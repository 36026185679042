.content {
  display: flex;
  width: 100%;
  height: 100vh;
  padding: 40px;
  background-color: #f4ede4;
}

.middle-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 200px;
  margin: 0 30px;
}