.content {
  max-width: 1232px;
  padding: 0 16px 16px;
  box-sizing: border-box;
  margin: 0 auto;
}

.layout-header {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 24px;
  box-shadow: 0 0 0 1px rgba(29, 28, 29, 0.13), 0 1px 3px 0 #00000014;
}

.layout-header-logo {
  height: 32px;
  margin-right: 10px;
}

.layout-header-title {
  font-size: 1.4rem;
  font-weight: 700;
}

.btn-logout {
  margin-left: auto;
}

.heading {
  padding: 64px 0;
  text-align: center;
  height: 80px;
}

.heading-title {
  font-size: 36px;
  font-weight: 900;
  line-height: 43px;
  margin-bottom: 12px;
}

.card {
  box-shadow: 0 18px 48px 0 #0000001a;
  border-radius: 8px;
  overflow: hidden;
  transition-duration: 0.5s;
}

.card:hover {
  cursor: pointer;
  box-shadow: 0 0 3rem rgba(0, 0, 0, .5);
}

.card-col:nth-child(3n + 1) .card-heading {
  background-color: #4a154b;
}

.card-col:nth-child(3n + 2) .card-heading {
  background-color: #0b4c8c;
}

.card-col:nth-child(3n + 3) .card-heading {
  background-color: #0b2440;
}

.card-heading {
  display: flex;
  justify-content: space-between;
  padding: 32px;
  color: white;
}

.card-heading-title {
  font-size: 28px;
  font-weight: 900;
  line-height: 1.36;
  height: 100px;
}

.card-heading-logo {
  width: 100px;
  margin-left: 16px;
}

.card-content {
  padding: 32px;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.service-price {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: 28px;
  font-weight: 900;
  line-height: 1.2143;
}

.service-details {
  padding-top: 4px;
  padding-bottom: 30px;
  padding-left: 10px;
  min-height: 150px;
}

.price-hightlight {
  margin-top: 30px;
}

.card-invalid {
  position: relative;
}

.card-invalid .card-heading,
.card-invalid .card-content {
  opacity: 0.5;
}

.card-invalid::before {
  content: '';
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%);
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
  transition-property: top, opacity;
  transition-duration: 0.5s;
}

.card-invalid-content {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 3;
  transition-property: top, opacity;
  transition-duration: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  text-align: center;
}

.card-invalid-content .payment-img {
  width: 50%;
  margin-bottom: 20px;
}

.card-invalid:hover {
  cursor: pointer;
}

.card-invalid:hover::before {
  opacity: 1;
  top: 0%;
}

.card-invalid:hover .card-invalid-content {
  opacity: 1;
  top: 0%;
  transition-delay: 0.1s;
}

.valid-until {
  margin-top: 20px;
  font-size: 16px;
}

.before-price {
  font-size: 16px;
  color: red;
}