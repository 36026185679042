.main-content {
  display: flex;
  height: 100vh;
  position: relative;
}

.main-content .login-content {
  display: flex;
  flex-direction: column;
  padding: 191px 72px 0 109px;
  width: 445px;
  box-sizing: content-box;
}

.main-content .login-content .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 34px;
  font-weight: bold;
  line-height: 1.5;
}

.main-content .login-content .border {
  border-top: 1px solid #e5e5e5;
  margin: 23px 0 42px;
}

.main-content .login-content .input-prefix {
  width: 150px;
  padding: 0 15px 0;
}

.main-content .left-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-image: url(images/login-background.png);
  background-size: auto 100%;
  background-position-x: center;
}

.main-content .left-content .header-content {
  padding: 25px 0 25px 80px;
}

.main-content .left-content .header-content img {
  height: 72px;
}

.btn-switch {
  margin-top: 10px;
}

.origin-select {
  margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
  .main-content {
    flex-direction: column;
  }

  .header-content {
    padding: 10px !important;
    text-align: center;
  }

  .login-content {
    padding: 20px 15px !important;
    width: unset !important;
    position: absolute;
    top: 20%;
    left: 20px;
    right: 20px;
    background-color: #126bbf8f;
    border-radius: 5px;

    :global(.ant-input-prefix) {
      display: none;
    }
  }

  .login-content .title {
    font-size: 30px !important;
    color: white;
  }

  .btn-switch {
    color: white !important;
  }
}